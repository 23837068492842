import React, { useContext, useState, useEffect, useRef } from "react";
import { StyleSheet, View, Image, useWindowDimensions } from "react-native";
import { Text } from "@geist-ui/react";
import { Context as PlanContext } from "../../../../../context/PlanContext";
import OverviewContent from "./OverviewContent";
import colors from "@res/colors";
import fonts from "@res/fonts";
import RemoteImage from "../../../../../components/RemoteImage";

const Overview = () => {
  const {
    state: { plan },
  } = useContext(PlanContext);

  return (
    <View style={{ paddingTop: 50 }}>
      <View style={styles.container}>
        <View style={styles.letterContainer}>
          <RemoteImage desiredWidth={80} uri={plan.prospectLogo} />
          <View style={{ marginVertical: 6 }} />
          <Text
            style={{
              color: "#002626",
              fontFamily: "Roobert",
            }}
            font="26px"
            b
          >
            Hey, {plan.prospectCompany} team
          </Text>
          <View style={{ marginVertical: 10 }} />
          <Text style={{ color: "#002626" }} font="16px">
            Thanks again for taking the time and allowing me to share a bit more
            on how Method can power debt repayment within {plan.prospectCompany}
          </Text>
          <Text style={{ color: "#002626" }} font="16px">
            I’ve gone ahead and mapped out an integration plan according to your
            needs, and generated a Sandbox API Key for you.
          </Text>
          <Text style={{ color: "#002626" }} font="16px">
            This will closely resemble the steps your engineering team will take
            to integrate Method into {plan.prospectCompany}’s flows.
          </Text>
          <Text style={{ color: "#002626" }} font="16px">
            If you have any questions don’t hesitate to reach out directly.
          </Text>
          <Text style={{ color: "#002626" }} font="16px">
            Best, the Method Team
          </Text>
        </View>

        <View
          style={[
            styles.overviewContainer,
            {
              background:
                "linear-gradient(" +
                120 +
                "deg, " +
                "#00946c" +
                ", " +
                "#002626" +
                ")",
            },
          ]}
        >
          <OverviewContent />
        </View>
      </View>
      <View
        style={{
          width: "100%",
          alignSelf: "center",
          paddingHorizontal: 50,
          paddingVertical: 20,
          marginTop: 150,
          borderRadius: 10,
          background:
            "linear-gradient(" +
            120 +
            "deg, " +
            "#002626" +
            ", " +
            "#005c4d" +
            ")",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <View style={{ width: "60%", paddingVertical: 30 }}>
          <Text
            style={{
              color: "white",
              paddingBottom: 0,
              marginBottom: 0,
              fontFamily: "Roobert",
            }}
            h1
          >
            {plan.prospectCompany}'s Test Drive
          </Text>
          <Text style={{ color: "white", opacity: 0.65 }} p>
            This page is already integrated with the Method API, so go ahead and
            take our API for a spin!
          </Text>
        </View>
        <View>
          <Image
            source={require("../../../../../../assets/images/rocket-dynamic-clay.png")}
            style={{
              height: 200,
              width: 200,
              alignSelf: "center",
            }}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    alignItems: "center",
  },
  letterContainer: {
    width: "35%",
  },
  overviewContainer: {
    width: "50%",
    padding: 50,
    maxWidth: 500,
    borderRadius: 5,
  },
  background: {
    position: "absolute",
    borderRadius: 5,
    left: 0,
    right: 0,
    top: 0,
    height: "100%",
    zIndex: -1,
  },
});

export default Overview;
